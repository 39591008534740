body {
  margin: 0;
  font-family: 'Inter', sans-serif !important;
  font-size: 1rem !important; 
}
#root{
  height: 100vh;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.stripe label {
  color: #6b7c93;
  font-weight: 300;
  letter-spacing: 0.025em;
  width: 100%;
}

.stripe button {
  white-space: nowrap;
  border: 0;
  outline: 0;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  color: #fff;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  background-color: #6772e5;
  text-decoration: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  margin-top: 10px;
}

.stripe button:hover {
  color: #fff;
  cursor: pointer;
  background-color: #7795f8;
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.stripe input,.StripeElement {
  display: block;
  margin: 10px 0 10px 0;
  /* max-width: 500px; */
  width: 100% !important;
  height: 44px;
  padding: 10px 14px;
  font-size: 16px;
  font-weight: 400;
  border: 0;
  outline: 0;
  border-radius: 12px;
  background: #F0F4F8;
}

.stripe input::placeholder {
  color: #aab7c4;
}

.stripe input:focus,
.stripe .StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.stripe .StripeElement.IdealBankElement,
.stripe .StripeElement.FpxBankElement,
.stripe .StripeElement.PaymentRequestButton {
  padding: 0;
}

.stripe .StripeElement.PaymentRequestButton {
  height: 40px;
}

.accepted {
  border: 1px solid #DCE1E7;
  border-radius: 12px;
  padding-left: 10px;
  padding-right: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.span-accep {
  color: #778699;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5;
  margin-right: 10px;
}
.cardp {
  margin-left: 5px
}
.htext {
  margin-bottom: 30px;
  color: #2C3F58;
  font-size: 28px;
  font-weight: 700;
  line-height: 1.1;
}
.colort {
  color: #778699;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
}
.text-c {
  color: #2C3F58;
  font-weight: 700;
}
.text-p {
  color: #2C3F58;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}
.currentCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.fc-3xgLq {
  display: none;
}
