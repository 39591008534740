/* .App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
.cursor {
  cursor: pointer;
}
.w-60 {
  width: 60%;
}
.css-swu57x-control, .css-2j5u7n-control, .css-i7hno2-control, .css-1p81987-control {
  box-shadow: 0 0 0 1px transparent !important;
}
.css-swu57x-control:hover {
  border-color: transparent !important;
}